import { createGlobalStyle } from "styled-components"
const GlobalStyle = createGlobalStyle`
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body {
    min-height: 100%;
  }
  body {
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  #root, #__next {
    isolation: isolate;
  }
  html,body {
    background: ${props => props.theme.page_background};
    background-image: linear-gradient(
      45deg,
      hsl(228deg 80% 6%) 0%,
      hsl(225deg 58% 8%) 16%,
      hsl(224deg 51% 10%) 23%,
      hsl(224deg 50% 12%) 29%,
      hsl(224deg 47% 14%) 34%,
      hsl(225deg 46% 17%) 39%,
      hsl(226deg 44% 19%) 43%,
      hsl(227deg 42% 21%) 48%,
      hsl(231deg 40% 24%) 52%,
      hsl(239deg 35% 28%) 57%,
      hsl(250deg 37% 31%) 61%,
      hsl(260deg 40% 33%) 66%,
      hsl(270deg 43% 34%) 71%,
      hsl(280deg 46% 35%) 77%,
      hsl(290deg 50% 36%) 84%,
      hsl(299deg 56% 35%) 100%
    );
    background-attachment: fixed;
    position:relative;    
    color:  ${props => props.theme.text_color};
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
    @media print {
     font-size: 12pt;
    }
  }
  h1 {
    font-size: ${props => props.theme.fontSizes.xxlarge};
    line-height: 120%;
    font-weight: 900;
  }
  h2 {
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 120%;
    font-weight: 700;
  }
  h3 {
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 120%;
    font-weight: 700;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 0.6em;
  }
`

export default GlobalStyle

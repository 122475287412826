const Theme = {
  colors: {
    black: "rgba(3,8,27,1)",
    black_shadow: "rgba(3,8,27,0.2)",
    gray: "rgba(77,75,71,1)",
    gray_dark: "rgba(26,25,24,1)",
    gray_med_light: "rgba(128,124,119,1)",
    gray_light: "rgba(178,174,166,1)",
    blue: "rgba(15, 28, 77, 1)",
    purple: "rgba(140, 40, 141, 1)",
    red: "rgba(219, 63, 41, 1)",
    orange: "rgba(237, 124, 21, 1)",
    yellow: "rgba(255, 184, 0, 1)",
    tan: "rgba(232, 226, 216, 1)",
    tan_light: "rgba(232, 226, 216, 0.5)",
    white: "rgba(255, 255, 255, 1)",
  },
  fontSizes: {
    small: "0.8rem",
    medium: "1rem",
    large: "1.5rem",
    xlarge: "2rem",
    xxlarge: "3rem",    
  },
  devices: {
    phone: "(min-width: 320px)",
    tablet: "(min-width: 768px)",
    desktop: "(min-width: 1024px)",
  },
  page_background: "rgb(33, 43, 80)",
  text_color: "rgb(232, 226, 216)",
}

export default Theme;

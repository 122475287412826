import React from "react"
import { ThemeProvider } from "styled-components"
import Theme from "./src/components/theme"
import GlobalStyle from "./src/components/globalStyle"
// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={Theme} {...props}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  )
}
